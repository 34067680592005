import { default as React } from 'react';
import { Theme, createStyles, withStyles } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import useTitle from '@/common/components/hooks/useTitle';
import SubHeader from '@/admin/components/common/subheader/SubHeader';
import { AnnouncementContainer } from '@/admin/components/announcement/AnnouncementContainer';
import Announcement from './Announcement';

interface Props {}

const styles = (theme: Theme) => createStyles({});

export const Component: React.FC<Props> = (props) => {
    useTitle(locale.t(locale.keys.pageTitle.announcement.index));

    return (
        <>
            <AnnouncementContainer.Provider>
                <SubHeader title={locale.t(locale.keys.pageTitle.announcement.index)} />
                <Announcement />
            </AnnouncementContainer.Provider>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
