import React, { FC } from 'react';
import { WithStyles, withStyles, Grid, Button, createStyles } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import MemberTable from './Table';

const styles = () =>
    createStyles({
        root: {
            width: '100%',
            gap: '16px',
        },
    });

interface Props extends WithStyles<typeof styles> {
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    formValue: schema.V1XStoragesOrderCreateRequest;
    setFormValue: React.Dispatch<React.SetStateAction<schema.V1XStoragesOrderCreateRequest>>;
    selectedUsers: string[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
    selectedGroups: string[];
    setSelectedGroups: React.Dispatch<React.SetStateAction<string[]>>;
}

const StepOne: FC<Props> = (props) => {
    const isDisableNext = () => {
        if (props.formValue.users.length === 0) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Grid container direction="column" id="steptwo-root" className={props.classes.root}>
            <MemberTable
                formValue={props.formValue}
                setFormValue={props.setFormValue}
                selectedUsers={props.selectedUsers}
                setSelectedUsers={props.setSelectedUsers}
                selectedGroups={props.selectedGroups}
                setSelectedGroups={props.setSelectedGroups}
            />
            <Grid id="steptwo-footer">
                <Button onClick={() => props.setActiveStep(0)}>{locale.t(locale.keys.memberAuthManagement.orderCreate.button.back)}</Button>
                <Button variant="contained" color="secondary" style={{ color: '#fff' }} onClick={() => props.setActiveStep(2)} disabled={isDisableNext()}>
                    {locale.t(locale.keys.memberAuthManagement.orderCreate.button.next)}
                </Button>
            </Grid>
            <Grid />
        </Grid>
    );
};

export default withStyles(styles)(StepOne);
