import { default as React, useState, useEffect } from 'react';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { AnnouncementContainer } from './AnnouncementContainer';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as errorHandler from '@/common/utils/errorHandler';
import { getStringByteCount } from '@/common/utils/webappUtil';
import { AnnouncementMail } from '@/common/constants/email';
import { Theme, createStyles, TextField, withStyles, Card, CardContent, WithStyles, Fab, Grid, FormControl, Select, InputLabel, Typography, FilledInput } from '@material-ui/core';
import * as errorLocale from '@/common/utils/locale/error-locale';

const styles = (theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '100%',
            minWidth: '100%',
            textAlign: 'center',
        },
        content: {
            textAlign: 'center',
        },
        sendButton: {
            color: 'white',
            borderRadius: 50,
            margin: theme.spacing.unit,
            minWidth: '240px',
        },
        textTitle: {
            width: '100%',
        },
        textBox: {
            width: '100%',
            fontSize: 12,
        },
        formControl: {
            margin: theme.spacing.unit,
            minWidth: '30%',
        },
        sendError: {
            width: '100%',
        },
        sendErrorText: {
            width: '100%',
        },
    });

interface Props extends WithStyles<typeof styles> {}

export const Component: React.FC<Props> = (props) => {
    const ui = useUI();
    const appContainer = AdminAppContainer.useContainer();
    const announcementContainer = AnnouncementContainer.useContainer();
    const { classes } = props;
    // 送信先
    const [destination, setDestination] = useState<string>('0');
    // 件名
    const [mailtitle, setMailTitle] = useState<string>(locale.t(locale.keys.pageTitle.announcement.mailtitlePlaceholder));
    // 本文
    const [mailtext, setMailText] = useState<string>('');
    // 送信エラーのメール
    const [errormails, setErrorMails] = useState<string>('');
    // 送信エラーメールがあるかどうか
    const [senderror, setSendError] = useState<boolean>(false);
    // 件名入力エラー
    const [titleError, setTitleError] = useState<string>('');
    // 本文入力エラー
    const [textError, setTextError] = useState<string>('');

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        ui.update(UI.Loaded);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    /**
     * 件名の編集時
     */
    const editMailTitle = (title: string) => {
        if (getStringByteCount(title) <= AnnouncementMail.SUBJECT_MAX_LENGTH) {
            setMailTitle(title);
            setTitleError('');
        } else {
            setTitleError(locale.t(locale.keys.validation.tooLongByte, { num: AnnouncementMail.SUBJECT_MAX_LENGTH }));
        }
    };

    /**
     * 本文の編集時
     */
    const editMailText = (text: string) => {
        if (getStringByteCount(text) <= AnnouncementMail.TEXT_MAX_LENGTH) {
            setMailText(text);
            setTextError('');
        } else {
            setTextError(locale.t(locale.keys.validation.tooLongByte, { num: AnnouncementMail.TEXT_MAX_LENGTH }));
        }
    };

    /**
     * 送信ボタンを押したときの処理
     */
    const preRequest = () => {
        ui.update(UI.Loading);
        // V1AnnouncementCreateRequestを作成
        const req: schema.V1AnnouncementCreateRequest = {
            destination,
            mailtitle,
            mailtext,
            errormails,
        };
        // 送信処理
        (async () => {
            try {
                const res = await announcementContainer.postSendAnouncementMail(appContainer.values.authorizationCode, req);
                if (res.success) {
                    // 送信成功のメッセージを表示
                    appContainer.updateMessage({
                        autoHideDuration: 3000,
                        isOpen: true,
                        message: locale.t(locale.keys.pageTitle.announcement.sendresult.success),
                        variant: Variants.success,
                    });
                    // フラグをセットし、送信エラー一覧を空にする
                    setSendError(false);
                    setErrorMails('');
                }
                ui.update(UI.Loaded);
            } catch (e) {
                ui.update(UI.Error);
                // エラーの分析
                const error = errorHandler.handleApiError(appContainer, e);
                if (error) {
                    switch (error.code) {
                        // 'ServiceUnavailable'
                        case errorLocale.translate(errorLocale.keys.E00003).code:
                            // レートリミットの可能性があるので時間を空けて再送
                            setSendError(true);
                            // 送信エラーのメール一覧をセット
                            setErrorMails(error.debugMessage || '');
                            break;
                    }
                }
            }
        })();
    };

    return (
        <>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <Grid container item xs={12} direction="column" justify="space-around" alignItems="center" spacing={24}>
                        <Grid container item xs={12} justify="space-around" alignItems="center">
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="filled-period" variant="filled">
                                    {locale.t(locale.keys.pageTitle.announcement.destination.index)}
                                </InputLabel>
                                {/* 送信先の選択 */}
                                <Select native value={destination} onChange={(event) => setDestination(event.target.value)} input={<FilledInput name="period" id="filled-period" />}>
                                    <option value={0}>{locale.t(locale.keys.pageTitle.announcement.destination.system_root)}</option>
                                    {/*<option value={1}>{locale.t(locale.keys.pageTitle.announcement.destination.allmember)}</option>*/}
                                    <option value={2}>{locale.t(locale.keys.pageTitle.announcement.destination.workspace)}</option>
                                    <option value={3}>{locale.t(locale.keys.pageTitle.announcement.destination.resend)}</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={12} direction="row" justify="space-around" alignItems="center" spacing={24}>
                            <Grid container item xs={12} sm={8} direction="column" justify="space-around" alignItems="center">
                                <Grid container item xs={12} direction="column" justify="space-around" alignItems="center">
                                    <Typography className={classes.textTitle} align="left">
                                        {locale.t(locale.keys.pageTitle.announcement.mailtitle)}
                                    </Typography>
                                    {/* 件名の入力 */}
                                    <TextField
                                        className={classes.textTitle}
                                        required
                                        value={mailtitle}
                                        variant="outlined"
                                        defaultValue=""
                                        onChange={(event) => editMailTitle(event.target.value)}
                                        error={titleError !== ''}
                                        helperText={titleError}
                                    />
                                </Grid>
                                <Grid container item xs={12} direction="column" justify="space-around" alignItems="center">
                                    <Typography className={classes.textTitle} align="left">
                                        {locale.t(locale.keys.pageTitle.announcement.mailtext)}
                                    </Typography>
                                    {/* メール本文の入力 */}
                                    <TextField
                                        className={classes.textBox}
                                        required
                                        multiline
                                        variant="outlined"
                                        rows={15}
                                        defaultValue=""
                                        onChange={(event) => editMailText(event.target.value)}
                                        error={textError !== ''}
                                        helperText={textError}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sm={4} direction="column" justify="space-around" alignItems="center">
                                <Typography className={classes.sendError} align="left">
                                    {locale.t(locale.keys.pageTitle.announcement.senderror)}
                                </Typography>
                                {/* エラーメール一覧の表示 */}
                                <TextField
                                    className={classes.sendErrorText}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    multiline
                                    variant="outlined"
                                    rows={19}
                                    error={senderror}
                                    value={errormails}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} style={{ width: '100%' }} justify="space-around" alignItems="center">
                            {/* 送信ボタン */}
                            <Fab
                                variant="extended"
                                className={classes.sendButton}
                                onClick={preRequest}
                                size="large"
                                color="secondary"
                                disabled={mailtitle === '' || mailtext === '' || titleError !== '' || textError !== '' || (!senderror && destination === '3')}
                            >
                                {locale.t(locale.keys.pageTitle.announcement.send)}
                            </Fab>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default withStyles(styles)(Component);
