import React, { FC, useEffect, useState } from 'react';
import { ListItemText, WithStyles, withStyles, Grid, TextField, Button, createStyles, FormControl, InputLabel, Select, FilledInput, MenuItem } from '@material-ui/core';
import * as locale from '@/common/utils/locale/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import * as webappUtil from '@/common/utils/webappUtil';
import { validationOutputFormat } from './useValidation';
import { getServices } from '@/user/models/workspaces/services/services';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';

const styles = () =>
    createStyles({
        root: {
            width: '100%',
            gap: '16px',
        },
    });

interface PresenterProps extends Props, WithStyles<typeof styles> {
    cloudList: schema.V1WorkspaceCloudSettingIndexResponse;
}

const getCloud = (cloudList: schema.V1WorkspaceCloudSettingIndexResponse) => {
    const resultList: schema.EndpointType[] = [];
    cloudList.settings.forEach((val) => {
        switch (val.serviceName) {
            case schema.EndpointType.Box:
            case schema.EndpointType.Dropbox:
            case schema.EndpointType.Googledrive:
            case schema.EndpointType.Googleteamdrive:
            case schema.EndpointType.Onedrive:
            case schema.EndpointType.Sharepointonline:
                if (val.is3L) {
                    resultList.push(val.serviceName);
                }
                break;
            default:
                break;
        }
    });
    return resultList;
};

const Presenter: FC<PresenterProps> = (props) => {
    const handleServiceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.setFormValue({
            ...props.formValue,
            service: event.target.value as schema.V1ObjectsServiceEnum,
        });
    };

    const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setFormValue({
            ...props.formValue,
            allowedDomain: event.target.value as schema.V1ObjectsServiceEnum,
        });
    };

    const isDisableNext = () => {
        if (props.validateMessage.service || props.validateMessage.allowedDomain) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Grid container direction="column" id="stepone-root" className={props.classes.root}>
            <FormControl variant="filled" style={{ margin: 0 }}>
                <InputLabel htmlFor="stepone-cloud" id="stepone-cloud-label">
                    {locale.t(locale.keys.memberAuthManagement.orderCreate.stepOne.label.cloudConnection)}
                </InputLabel>
                <Select value={props.formValue.service} onChange={handleServiceChange} input={<FilledInput id="stepone-cloud" />}>
                    {getCloud(props.cloudList).map((service) => (
                        <MenuItem key={service} value={service}>
                            <ListItemText primary={webappUtil.getServiceText(service)} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                id="stepone-domain"
                variant="filled"
                value={props.formValue.allowedDomain}
                label={locale.t(locale.keys.memberAuthManagement.orderCreate.stepOne.label.allowedDomain)}
                onChange={handleDomainChange}
                error={props.validateMessage.allowedDomain !== ''}
                helperText={props.validateMessage.allowedDomain}
                inputProps={{
                    'data-testid': 'domain-form',
                }}
            />
            <Grid id="stepone-footer">
                <Button variant="contained" color="secondary" style={{ color: '#fff' }} onClick={() => props.setActiveStep(1)} disabled={isDisableNext()} data-testid="stepone-next">
                    {locale.t(locale.keys.memberAuthManagement.orderCreate.button.next)}
                </Button>
            </Grid>
            <Grid />
        </Grid>
    );
};

export const StyledPresenter = withStyles(styles)(Presenter);

interface Props {
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    formValue: schema.V1XStoragesOrderCreateRequest;
    setFormValue: React.Dispatch<React.SetStateAction<schema.V1XStoragesOrderCreateRequest>>;
    validateMessage: validationOutputFormat;
}

const StepOne: FC<Props> = (props) => {
    const [reqData, setReqData] = useState<schema.V1WorkspaceCloudSettingIndexResponse>({
        settings: [],
    });
    const appContainer = AdminAppContainer.useContainer();
    const ui = useUI(UI.Loaded);

    const updateUIStatus = (state: UI) => {
        if (ui) {
            ui.update(state);
        }
    };

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        (async () => {
            try {
                if (appContainer.values.signinWorkspaceObject.id) {
                    updateUIStatus(UI.Loading);
                    const cloudSetting = await getServices(appContainer.values.signinWorkspaceObject.id, appContainer.values.authorizationCode);
                    if (cloudSetting == null) {
                        // このエラーが発生することは通常では考えられない
                        // servicesが見つからないときにエラーレスポンスではなくnullが返ってきたのでこの処理を追加
                        throw Error('Cloud Services is not found.');
                    }
                    setReqData(cloudSetting);
                    updateUIStatus(UI.Loaded);
                } else {
                    // このエラーが発生することは考えられない。
                    // ここにたどり着く前にAPIリクエストでエラーがthrowされるから
                    throw Error('Workspace ObjectId is not found. Caused by React State.');
                }
            } catch (e) {
                updateUIStatus(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <StyledPresenter {...props} cloudList={reqData} />;
};

export default withStyles(styles)(StepOne);
