import { createContainer } from 'unstated-next';
import * as schema from '@/bundles/schema/typescript/schema';
import { AvailableMethod } from '@/common/constants/authorization';
import { axiosFactory } from '@/common/utils/axiosFactory';
import { default as consts } from '@/common/constants';

const useAnnouncementContainer = () => {
    /**
     * apiに送信リクエストを投げる
     */
    const postSendAnouncementMail = async (auth: string, mailrequest: schema.V1AnnouncementCreateRequest): Promise<schema.V1AnnouncementCreateResponse> => {
        const response = await axiosFactory.post<schema.V1AnnouncementCreateResponse>(`https://${consts.environment.api.hostname}/v1/announcement`, mailrequest, {
            headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
        });

        return response.data;
    };

    return {
        postSendAnouncementMail,
    };
};
export const AnnouncementContainer = createContainer(useAnnouncementContainer);
