import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { getMemberListFromGroupList, useGroup } from '@/common/models/groups/useGroup';
import { indexXStorageStatus } from '@/common/api/workspace/x-storage-status';
import * as schema from '@/bundles/schema/typescript/schema';
import { styles as baseTabStyles } from '@/common/components/Tab/style';
import { styles as baseTableStyles } from '@/common/components/Table/style';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';
import * as locale from '@/common/utils/locale/locale';
import { Grid, InputBase, Paper, Tab, Tabs, Theme, Typography, WithStyles, createStyles, withStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import GroupTable from './GroupTable';
import MemberTable from './MemberTable';

const styles = (theme: Theme) =>
    createStyles({
        ...baseTableStyles(),
        ...baseTabStyles(theme),
        root: {
            width: '100%',
            gap: '16px',
        },
        searchRoot: {
            width: '100%',
            display: 'flex',
            overflowX: 'auto',
        },
        searchIconButton: {
            marginBlock: 'auto',
            marginInline: '10px',
        },
        searchInput: {
            width: `calc(100% - 64px)`,
            padding: '8px',
        },
        table: {
            width: '100%',
            padding: 0,
        },

        checkCell: {
            width: 40,
            maxWidth: 40,
            margin: 4,
            wordBreak: 'break-all',
        },
        avatarCell: {
            width: 40,
            maxWidth: 40,
            margin: 4,
            wordBreak: 'break-all',
        },
        mailCell: {
            width: 'auto',
            margin: 4,
            wordBreak: 'break-all',
        },
        roleCell: {
            width: '12%',
            margin: 4,
            wordBreak: 'break-all',
        },
        authCell: {
            width: '12%',
            margin: 4,
            wordBreak: 'break-all',
        },
        statusCell: {
            width: '20%',
            margin: 4,
            wordBreak: 'break-all',
        },

        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            display: 'flex',
            gap: '4px',
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: 'auto 0',
        },
    });

interface PresenterProps extends WithStyles<typeof styles>, Props {
    responseData: schema.V1ObjectsXStoragesStatus[];
    setResponseData: React.Dispatch<React.SetStateAction<schema.V1ObjectsXStoragesStatus[]>>;
    groupRows: schema.V1WorkspaceGroupsIndexResponse;
}

// Humble Object
// APIをモックするのが大変なので、画面側で重要な部分だけをPresenterとして切り出し
const Presenter: FC<PresenterProps> = (props) => {
    const [search, setSearch] = useState({
        searchText: '',
    });
    const [tabIndex, setTabIndex] = React.useState(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        const selectedUserListFromSelectedGroup = getMemberListFromGroupList(props.selectedGroups, props.groupRows.groups);
        // 選択済みグループから取得したユーザーIDをもとにschema.V1ObjectsWorkspaceuserLarge[]に変換する
        let selectedUserListFromGroup = [] as schema.V1ObjectsWorkspaceuserLarge[];
        props.responseData.forEach((user) => {
            if (selectedUserListFromSelectedGroup.some((selectedUser) => selectedUser === user.user.id)) {
                selectedUserListFromGroup.push(user.user);
            }
        });

        let selectedUserListFromUser = [] as schema.V1ObjectsWorkspaceuserLarge[];
        props.responseData.forEach((user) => {
            if (props.selectedUsers.some((selectedUser) => selectedUser === user.user.id)) {
                selectedUserListFromUser.push(user.user);
            }
        });

        // 選択ユーザーと選択グループに所属するメンバーで重複を除いてステートに追加。
        const updatedUserListForm = selectedUserListFromGroup.concat(selectedUserListFromUser);
        const uniqueArray = updatedUserListForm.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        props.setFormValue({
            ...props.formValue,
            users: uniqueArray,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedUsers, props.selectedGroups]);

    const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch({
            ...search,
            searchText: event.target.value,
        });
    };

    return (
        <>
            <Paper className={props.classes.searchRoot}>
                <Grid aria-label="Search" className={props.classes.searchIconButton}>
                    <Search />
                </Grid>
                <InputBase className={props.classes.searchInput} value={search.searchText} onChange={handleSearchText} inputProps={{ 'data-testid': 'search-input' }} />
            </Paper>
            {/* selected.lengthが0のときなぜか文字が表示されないので、stringに変換して表示させる */}
            <Typography>
                {locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.tips.sendSum, {
                    n: props.selectedUsers.length + props.selectedGroups.length === 0 ? '0' : props.selectedUsers.length + props.selectedGroups.length,
                    i: props.formValue.users.length === 0 ? '0' : props.formValue.users.length,
                })}
            </Typography>
            <Paper className={props.classes.root}>
                <div className={props.classes.tabs} style={{ marginInlineStart: '4px' }}>
                    <Tabs value={tabIndex} onChange={handleTabChange} classes={{ root: props.classes.tabsContainer, indicator: props.classes.indicator }}>
                        <Tab label={locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.tab.userTable)} className={props.classes.tabTags} classes={{ selected: props.classes.tabSelected }} />
                        <Tab
                            label={locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.tab.groupTable)}
                            className={props.classes.tabTags}
                            classes={{ selected: props.classes.tabSelected }}
                        />
                    </Tabs>
                </div>
                {tabIndex === 0 ? (
                    <>
                        <MemberTable
                            formValue={props.formValue}
                            setFormValue={props.setFormValue}
                            userData={props.responseData}
                            responseData={props.responseData}
                            searchText={search.searchText}
                            selectedUsers={props.selectedUsers}
                            setSelectedUsers={props.setSelectedUsers}
                        />
                    </>
                ) : tabIndex === 1 ? (
                    <>
                        <GroupTable
                            formValue={props.formValue}
                            setFormValue={props.setFormValue}
                            groupRows={props.groupRows}
                            searchText={search.searchText}
                            userData={props.responseData}
                            selectedGroups={props.selectedGroups}
                            setSelectedGroups={props.setSelectedGroups}
                        />
                    </>
                ) : (
                    <></>
                )}
            </Paper>
        </>
    );
};

export const StyledPresenter = withStyles(styles)(Presenter);

interface Props extends WithStyles<typeof styles> {
    formValue: schema.V1XStoragesOrderCreateRequest;
    setFormValue: React.Dispatch<React.SetStateAction<schema.V1XStoragesOrderCreateRequest>>;
    selectedUsers: string[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
    selectedGroups: string[];
    setSelectedGroups: React.Dispatch<React.SetStateAction<string[]>>;
}

// 選択されているユーザの初期化
// Step2の画面で毎回ユーザー、グループの一覧をfetchするので整合性を保つために初期化する
const initialSelectedUsers = (selected: string[], reqData: schema.V1ObjectsXStoragesStatus[]) => {
    const initSelected: string[] = [];
    reqData.forEach((val) => {
        if (selected.indexOf(val.user.id) !== -1) {
            initSelected.push(val.user.id);
        }
    });
    // 選択されたユーザID
    return initSelected;
};

// 選択されているグループの初期化
// Step2の画面で毎回ユーザー、グループの一覧をfetchするので整合性を保つために初期化する
const initialSelectedGroups = (selected: string[], reqData: schema.V1WorkspaceGroupsShowResponse[]) => {
    const initSelected: string[] = [];
    reqData.forEach((val) => {
        if (selected.indexOf(val.id) !== -1) {
            initSelected.push(val.id);
        }
    });
    // 選択されたグループID
    return initSelected;
};

const TableContainer: FC<Props> = (props) => {
    // リクエストで受け取ったメンバー一覧データを保存するためだけのステート
    const [reqData, setReqData] = useState<schema.V1ObjectsXStoragesStatus[]>([]);
    const [groupData, setGroupData] = useState<schema.V1WorkspaceGroupsIndexResponse>({
        count: 0,
        groups: [],
    });
    const appContainer = AdminAppContainer.useContainer();
    const group = useGroup();
    const ui = useUI(UI.Loaded);

    const updateUIStatus = (state: UI) => {
        if (ui) {
            ui.update(state);
        }
    };

    useEffect(() => {
        appContainer.updateLoadingState(ui.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ui]);

    useEffect(() => {
        (async () => {
            try {
                updateUIStatus(UI.Loading);
                const data = await indexXStorageStatus(appContainer.values.authorizationCode, appContainer.values.signinWorkspaceObject.id!, props.formValue.service, props.formValue.allowedDomain);

                // 選択済みユーザーをfetchされた内容に合わせて初期化
                const updatedSelectUsers = initialSelectedUsers(props.selectedUsers, data.users);
                props.setSelectedUsers([...updatedSelectUsers]);
                setReqData(data.users);
            } catch (e) {
                updateUIStatus(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }

            try {
                const val = await group.indexGroupData(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
                setGroupData(val);
                // 選択済みグループをfetchされた内容に合わせて初期化
                const updatedSelecteGroups = initialSelectedGroups(props.selectedGroups, val.groups);
                props.setSelectedGroups([...updatedSelecteGroups]);
                updateUIStatus(UI.Loaded);
            } catch (e) {
                updateUIStatus(UI.Loaded);
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.setFormValue]);

    return (
        <StyledPresenter
            formValue={props.formValue}
            responseData={reqData}
            setResponseData={setReqData}
            setFormValue={props.setFormValue}
            groupRows={groupData}
            selectedUsers={props.selectedUsers}
            setSelectedUsers={props.setSelectedUsers}
            selectedGroups={props.selectedGroups}
            setSelectedGroups={props.setSelectedGroups}
        />
    );
};

export default withStyles(styles)(TableContainer);
