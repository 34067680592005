import React, { FC, useState, useEffect } from 'react';
import { Search } from '@material-ui/icons';
import { Theme, createStyles, withStyles, WithStyles, Table, TableBody, TableCell, TableHead, TableRow, Paper, TablePagination, Button, Modal } from '@material-ui/core';
import locale from '@/common/utils/locale';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { DEFAULT_ROWS_PER_PAGE } from '@/common/constants/pagination';
import * as schema from '@/bundles/schema/typescript/schema';
import * as errorHandler from '@/common/utils/errorHandler';
import Email from '@material-ui/icons/Email';
import { agentSignIn } from '@/common/utils/authManagement/agentSignIn';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import EmailModal from './EmailModal';
import { useValidation } from '@/admin/components/auth-management/create-order-modal/useValidation';
import SendStatus from '@/admin/components/common/SendStatus';
import { V1ObjectsMailResourceType } from '@/bundles/schema/typescript/schema';

export type Row = {
    historyId: string;
    createDate: string;
    email: string;
    name: string;
    authStatus: string;
    memberId: string;
    memberRole: number;
    user: schema.V1ObjectsWorkspaceuserLarge;
    master: schema.V1ObjectsXStoragesOrderMaster;
};

// TableHeader
const headerStyles = (theme: Theme) =>
    createStyles({
        avatarRow: {
            width: '0.5%',
            // width: 5,
            padding: 0,
        },
        headerFont: {
            '&& th': {
                fontSize: 12,
                fontWeight: 'bold',
                color: '#333333',
            },
        },

        // TODO:後で幅を決定する
        // セルの幅の割合を一定にするためのスタイル
        // 合計で100%になるように設定
        checkCell: {
            width: '10%',
            padding: 4,
            wordBreak: 'break-all',
        },
        mailCell: {
            width: '30%',
            padding: 4,
            wordBreak: 'break-all',
        },
        nameCell: {
            width: '28%',
            padding: 4,
            wordBreak: 'break-all',
        },
        authCell: {
            width: '12%',
            padding: 4,
            wordBreak: 'break-all',
        },
        statusCell: {
            width: '20%',
            padding: 4,
            wordBreak: 'break-all',
        },

        // TableCellコンポーネント内を横並びにするためのスタイル
        gridInsideHeaderCell: {
            display: 'flex',
            gap: '4px',
        },
        // TableCellコンポーネント内部の要素を上下中央寄せするためのスタイル
        gridInsideHeaderCellText: {
            margin: 'auto 0',
        },
    });

interface HeaderProps extends WithStyles<typeof headerStyles> {}

// TableRoot
const rootStyles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        table: {
            minWidth: 700,
            padding: 0,
        },
        modalWindow: {
            backgroundColor: '#fff',
            flexGrow: 1,
            height: '100%',
            padding: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            outline: 'none',
        },
    });

interface RootProps extends WithStyles<typeof rootStyles> {
    responseData: Row[];
}
const Root: FC<RootProps> = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [rows, setRows] = useState<Row[]>([]);
    const [isOpen, setOpen] = useState(false);
    const [formValue, setFormValue] = useState<schema.V1XStoragesOrderCreateRequest>({
        service: schema.V1ObjectsServiceEnum.Nonecloud,
        allowedDomain: '',
        users: [],
        body: locale.t(locale.keys.memberAuthManagement.orderCreate.stepThree.placeholder.mailBody),
        cc: [''],
        limit: 30,
        title: locale.t(locale.keys.memberAuthManagement.orderCreate.stepThree.placeholder.mailTitle),
    });
    const validateMessage = useValidation(formValue);

    const appContainer = AdminAppContainer.useContainer();
    const ui = useUI(UI.Loaded);

    const updateUIStatus = (state: UI) => {
        if (ui) {
            ui.update(state);
        }
    };

    const switchModal = () => {
        setOpen(!isOpen);
    };

    //
    const setEmail = (row: Row) => {
        const users: schema.V1ObjectsWorkspaceuserLarge[] = [row.user];
        setFormValue((prevState) => {
            return {
                ...prevState,
                users: users,
                allowedDomain: row.master.allowedDomain,
                service: row.master.service,
                cc: [''],
            };
        });
    };

    //代理ログイン関数
    const onClickAgentSignIn = (targetEmail: string, targetMemberId: string) => async () => {
        try {
            updateUIStatus(UI.Loading);
            await agentSignIn(appContainer.values.authorizationCode, appContainer.values.signinWorkspaceObject.displayId, targetEmail, targetMemberId, document.location.href);
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        } finally {
            updateUIStatus(UI.Loaded);
        }
    };

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    useEffect(() => {
        if (props.responseData) {
            setRows(props.responseData);
        }
    }, [props.responseData]);

    return (
        <Paper className={props.classes.root}>
            <div className={props.classes.tableWrapper}>
                <Table className={props.classes.table}>
                    <StylesHeader />
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow style={{ height: 49 * 1 }} key={row.historyId}>
                                <TableCell padding="none" />
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{row.name}</TableCell>
                                <TableCell>{row.authStatus}</TableCell>
                                {// 未認可の場合のみメールの再送信が可能
                                row.authStatus === locale.t(locale.keys.memberAuthManagement.orderCreate.stepTwo.table.popupMenu.auth.still) ? (
                                    <TableCell align="center">
                                        <Button
                                            variant="outlined"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                marginTop: '5px',
                                                marginBottom: '5px',
                                                textAlign: 'center',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                fontStyle: 'normal',
                                                fontStretch: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                color: '#ff7300',
                                                backgroundColor: 'white',
                                                verticalAlign: 'middle',
                                                height: '28px',
                                                width: '106px',
                                                border: '1px solid orange',
                                            }}
                                            onClick={() => {
                                                switchModal();
                                                setEmail(row);
                                            }}
                                        >
                                            <Email style={{ transform: `scale(1.0)` }} />
                                            <span style={{ paddingBottom: '2px' }}>{locale.t(locale.keys.action.invitationReSend)}</span>
                                        </Button>
                                    </TableCell>
                                ) : (
                                    <TableCell align="center">
                                        <Button
                                            variant="outlined"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                marginTop: '5px',
                                                marginBottom: '5px',
                                                textAlign: 'center',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                fontStyle: 'normal',
                                                fontStretch: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                color: 'gray',
                                                backgroundColor: 'white',
                                                verticalAlign: 'middle',
                                                height: '28px',
                                                width: '106px',
                                                border: '1px solid gray',
                                            }}
                                            disabled
                                        >
                                            <Email style={{ transform: `scale(1.0)` }} />
                                            <span style={{ paddingBottom: '2px' }}>{locale.t(locale.keys.action.invitationReSend)}</span>
                                        </Button>
                                    </TableCell>
                                )}
                                <TableCell align="center">
                                    <SendStatus type={V1ObjectsMailResourceType.XStoragesOrder} wsUserId={row.user.id} email={row.email} objectId={row.historyId} />
                                </TableCell>
                                {row.memberRole === 100 ? (
                                    <TableCell align="center">
                                        <Button
                                            variant="outlined"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                marginTop: '5px',
                                                marginBottom: '5px',
                                                textAlign: 'center',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                fontStyle: 'normal',
                                                fontStretch: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                color: 'white',
                                                backgroundColor: '#ff3d00',
                                                verticalAlign: 'middle',
                                                height: '28px',
                                                width: '128px',
                                                border: '1px solid orange',
                                            }}
                                            onClick={onClickAgentSignIn(row.email, row.memberId)}
                                        >
                                            <span style={{ paddingBottom: '2px' }}>{locale.t(locale.keys.memberAuthManagementDetail.action.adminLogin)}</span>
                                        </Button>
                                    </TableCell>
                                ) : (
                                    <TableCell align="center">
                                        <Button
                                            variant="outlined"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                marginTop: '5px',
                                                marginBottom: '5px',
                                                textAlign: 'center',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                fontStyle: 'normal',
                                                fontStretch: 'normal',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                color: 'white',
                                                backgroundColor: 'gray',
                                                verticalAlign: 'middle',
                                                height: '28px',
                                                width: '128px',
                                                border: '1px solid gray',
                                            }}
                                            disabled
                                        >
                                            <span style={{ paddingBottom: '2px' }}>{locale.t(locale.keys.memberAuthManagementDetail.action.adminLogin)}</span>
                                        </Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <Modal open={isOpen} onClose={switchModal}>
                <div className={props.classes.modalWindow}>
                    <EmailModal formValue={formValue} setFormValue={setFormValue} validateMessage={validateMessage} onClose={switchModal} />
                </div>
            </Modal>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                labelRowsPerPage={locale.t(locale.keys.table.rowsPerPage)}
                labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => (
                    <span>{locale.t(locale.keys.table.displayedRowsArgs, { from: paginationInfo.from, to: paginationInfo.to, count: paginationInfo.count })}</span>
                )}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': locale.t(locale.keys.table.previousPage),
                }}
                nextIconButtonProps={{
                    'aria-label': locale.t(locale.keys.table.nextPage),
                }}
                onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, p: number) => handleChangePage(event, p)}
                onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChangeRowsPerPage(event)}
                data-testid="pagenation-root"
            />
        </Paper>
    );
};

const Header: FC<HeaderProps> = (props) => {
    return (
        <TableHead>
            <TableRow className={props.classes.headerFont}>
                <TableCell className={props.classes.avatarRow} />
                <TableCell align="left">
                    <div className={props.classes.gridInsideHeaderCell}>
                        <p className={props.classes.gridInsideHeaderCellText}>{locale.t(locale.keys.memberAuthManagementDetail.header.email)}</p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell>
                    <div className={props.classes.gridInsideHeaderCell}>
                        <p className={props.classes.gridInsideHeaderCellText}>{locale.t(locale.keys.memberAuthManagementDetail.header.memberName)}</p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell>
                    <div className={props.classes.gridInsideHeaderCell}>
                        <p className={props.classes.gridInsideHeaderCellText}>{locale.t(locale.keys.memberAuthManagementDetail.header.authStatus)}</p>
                        <Search />
                    </div>
                </TableCell>
                <TableCell align="center">{locale.t(locale.keys.memberAuthManagementDetail.header.sendEmail)}</TableCell>
                <TableCell align="center">{locale.t(locale.keys.memberAuthManagementDetail.header.emailStatus)}</TableCell>
                <TableCell align="center">{locale.t(locale.keys.memberAuthManagementDetail.header.admin)}</TableCell>
            </TableRow>
        </TableHead>
    );
};

const StylesHeader = withStyles(headerStyles)(Header);

export default withStyles(rootStyles)(Root);
