import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import logger from '@/common/utils/logger';
import { getSplitNewLineString, csvPaser, sleep } from '@/common/utils/webappUtil';
import * as schema from '@/bundles/schema/typescript/schema';
import { csvFormat4Endpoint } from '@/common/constants/csvFormat';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { Variants } from '@/common/components/messages/CommonMessage';
import locale from '@/common/utils/locale';
import * as errLocale from '@/common/utils/locale/error-locale';
import * as errorHandler from '@/common/utils/errorHandler';
import * as encoding from 'encoding-japanese';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { isNullOrEmptyArray } from '@/common/utils/array-helper/arrayHelper';
import * as endpointModel from '@/common/api/endpoints/collective/endpoint';
import { csvImportCheck } from './validation';
import * as workspace from '@/common/api/workspace/workspace';
import { exportCsv } from './exportCsv';
import environment from '@/common/constants/environment';

const useCollectiveRegisterContainer = () => {
    const appContainer = AdminAppContainer.useContainer();
    const [csvFileName, setCsvFileName] = useState<string>(locale.t(locale.keys.memberInvitation.collectiveInvite.noneFile));
    const [fileInput, setFileInput] = useState<Blob | null>(null);
    const [code, setCode] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [buttonDisable, setButtonDisable] = useState<boolean>(false);
    const ui = useUI();
    let blankLine: boolean = false;
    const endpoints: schema.V1ObjectsEndpointsCollectiveSetting[] = []; //APIへのリクエストになる

    useEffect(() => {
        ui.update(UI.Loaded);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setButtonDisable(true);
        if (!loading && fileInput) {
            setButtonDisable(false);
        }
    }, [loading, fileInput]);

    /**
     * ファイル選択時の処理
     * @param file csvfile or null
     */
    const onSelectedCSV = (file: File[] | null) => {
        if (file && file.length > 0) {
            // ファイル名を取得
            const fileName = file[0].name;
            // 事前に文字コードの確認のためにファイルを開く
            const reader = new FileReader();
            reader.readAsBinaryString(file[0]);
            reader.onload = () => {
                const str = reader.result as string;
                // BOM付きかチェック
                if (csvFormat4Endpoint.CSVFORMAT_BOM.test(str)) {
                    // BOM付きの場合UTF8
                    setCode('UTF8');
                } else {
                    // 文字コード判定
                    const detectResult = encoding.detect(str);
                    if (!detectResult) {
                        // 判定不能の文字コードはUTF8にしておく
                        logger.error(`onSelectedCSV() unknown encoding. fileName:${fileName}`);
                        setCode('UTF8');
                    } else {
                        const detectEncoding = detectResult as string;
                        setCode(detectEncoding);
                    }
                }
            };
            setCsvFileName(fileName);
            setFileInput(file[0]);
        } else {
            setCsvFileName(locale.t(locale.keys.manageShareDevice.collective.noneFile));
            setFileInput(null);
        }
    };

    /**
     * ファイル読み込み失敗時にファイルをクリアする
     */
    const clearSelectedFile = () => {
        setLoading(false);
        setCsvFileName(locale.t(locale.keys.manageShareDevice.collective.noneFile));
        setFileInput(null);
    };

    /**
     * リクエストの作成とレスポンスの処理
     * @param recreate true:再作成 false:追加
     */
    const sendEndpointRequest = async (importMode: schema.ImportMode) => {
        try {
            setLoading(true);
            ui.update(UI.Loading);
            const request: schema.V1EndpointsCollectiveCreateRequest = {
                workspace: appContainer.values.signinWorkspaceObject.id!,
                settings: endpoints,
                filename: csvFileName,
                importMode,
            };

            await endpointModel.createCollectiveEndpoint(request, appContainer.values.authorizationCode);

            appContainer.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.request, { num: endpoints.length }),
                variant: Variants.success,
            });
            setLoading(false);
            ui.update(UI.Loaded);
        } catch (e) {
            // レスポンスエラーの時も選択ファイルクリア
            clearSelectedFile();
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    /**
     * 「通常業務/転送先を登録」ボタンを押したときの処理
     * @param recreate true:再作成 false:追加
     */
    const onImportCsv = async (importMode: schema.ImportMode) => {
        setLoading(true);
        ui.update(UI.Loading);
        // ファイルサイズ取得(0byteの場合エラー)
        if (fileInput && fileInput.size) {
            // ファイルの読み込み
            const reader = new FileReader();
            // 読み込み成功
            reader.onload = async () => {
                const fileInput = reader.result as string;
                const lineInput = getSplitNewLineString(fileInput);
                blankLine = false;
                if (lineInput.length <= csvFormat4Endpoint.CSV_HEADER) {
                    // ヘッダー行のみのファイルはエラー
                    appContainer.updateMessage({
                        isOpen: true,
                        message: locale.t(locale.keys.validation.csvemptyerror),
                        variant: Variants.error,
                    });
                }
                let mailList: string[] = [];
                try {
                    const result = await workspace.findWorkspaceUser(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
                    for (let i = 0; i < result.users.length; i++) {
                        mailList.push(result.users[i].invitationEmail);
                    }
                } catch (e) {
                    ui.update(UI.Loaded);
                    errorHandler.handleApiError(appContainer, e);
                }
                // mailListが取得出来ていたら次に進む
                if (mailList[0]) {
                    for (let i = csvFormat4Endpoint.CSV_HEADER; i < lineInput.length; i += 1) {
                        // CSVパース
                        let spliteComma = [] as string[];
                        try {
                            spliteComma = csvPaser(lineInput[i], code);
                        } catch (e) {
                            appContainer.updateMessage({
                                isOpen: true,
                                message: locale.t(locale.keys.validation.csvemptyerror, { row: i }),
                                variant: Variants.error,
                            });
                            ui.update(UI.Loaded);
                            break;
                        }
                        // 入力形式チェック
                        const { success, endpoint, blank, error } = csvImportCheck(blankLine, spliteComma, i, environment.tenant, false);
                        if (!success) {
                            appContainer.updateMessage({
                                ...error,
                            });
                            ui.update(UI.Loaded);
                            break;
                        }
                        blankLine = blank;
                        // Emailがワークスペースに存在していることを確認
                        if (!mailList.includes(spliteComma[csvFormat4Endpoint.COL_IDX_EMAIL]) && !blankLine) {
                            // 登録されていないアドレスはエラー
                            appContainer.updateMessage({
                                isOpen: true,
                                message: errLocale.translate(errLocale.keys.E08006, { index: i + 1, row: csvFormat4Endpoint.COL_IDX_EMAIL + 1 }).message,
                                variant: Variants.error,
                            });
                            ui.update(UI.Loaded);
                            break;
                        }
                        if (!blank) endpoints.push(endpoint);

                        // 1000行読み込んだ場合
                        if (i >= csvFormat4Endpoint.CSV_MAX_LINE + csvFormat4Endpoint.CSV_HEADER - 1) {
                            if (!isNullOrEmptyArray(endpoints)) {
                                // apiにリクエスト
                                sendEndpointRequest(importMode);
                                break;
                            }
                        }
                        // 最終行まで読み込んだ場合
                        if (i === lineInput.length - 1) {
                            if (blankLine) {
                                if (!isNullOrEmptyArray(endpoints)) {
                                    // apiにリクエスト
                                    sendEndpointRequest(importMode);
                                } else {
                                    // データ行なし
                                    appContainer.updateMessage({
                                        isOpen: true,
                                        message: locale.t(locale.keys.validation.csvemptyerror),
                                        variant: Variants.error,
                                    });
                                }
                            } else {
                                // qsのcsvインポート仕様に合わせる
                                // 最終行はEOFの前に改行がなければエラー
                                appContainer.updateMessage({
                                    isOpen: true,
                                    message: locale.t(locale.keys.validation.csvimporteoferror, { row: lineInput.length }),
                                    variant: Variants.error,
                                });
                                ui.update(UI.Loaded);
                            }
                        }
                    }
                }
                clearSelectedFile();
            };
            // 読み込み失敗
            reader.onerror = () => {
                clearSelectedFile();
                ui.update(UI.Loaded);
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.csvemptyerror),
                    variant: Variants.error,
                });
            };

            // 読み込み開始
            if (code === 'SJIS' || code === 'UTF8') {
                reader.readAsText(fileInput, code);
            } else {
                clearSelectedFile();
                // それ以外対応していない文字コードとして扱う
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.invalidCharacterCode),
                    variant: Variants.error,
                });
            }
        } else {
            clearSelectedFile();
            appContainer.updateMessage({
                isOpen: true,
                message: locale.t(locale.keys.validation.csvemptyerror),
                variant: Variants.error,
            });
        }
    };
    /**
     * CSVエクスポートボタンを押したときの処理
     */
    const clickExportCsv = async () => {
        ui.update(UI.Loading);
        try {
            // users._idのリストを取得
            const wsUsers = await workspace.findWorkspaceUser(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
            const userIds = wsUsers.users.map((wsUser) => wsUser.user).filter((userId) => userId !== undefined && userId !== null && userId !== '');
            const maxCount = await endpointModel.getCollectiveEndpointCount(appContainer.values.signinWorkspaceObject.id!, userIds, appContainer.values.authorizationCode);
            const limit = 50;
            let exportData: schema.V1ObjectsEndpointsCollectiveSetting[] = [];
            for (let offset = 0; offset < maxCount.total; offset += limit) {
                const result = await endpointModel.exportCsvRequest(appContainer.values.signinWorkspaceObject.id!, userIds, appContainer.values.authorizationCode, limit, offset);
                exportData = [...exportData, ...result.settings];
                await sleep(1000);
            }
            const lang = appContainer.values.signinWorkspaceUserObject.language;
            // csvデータに整形
            const csvData = exportCsv(exportData, lang, environment.tenant);

            let uri = URL.createObjectURL(csvData['blob']);
            //リンクタグを作る
            let link = document.createElement('a');
            const filename = `ImportEndpoint_template_${lang}.csv`;
            link.download = filename;
            link.href = uri;

            //作ったリンクタグをクリックさせる
            document.body.appendChild(link);
            link.click();

            //クリックしたら即リンクタグを消す
            document.body.removeChild(link);
            ui.update(UI.Loaded);
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
        }
    };

    return {
        csvFileName,
        fileInput,
        buttonDisable,
        ui,
        onSelectedCSV,
        onImportCsv,
        csvImportCheck,
        clickExportCsv,
    };
};

export const CollectiveRegisterContainer = createContainer(useCollectiveRegisterContainer);
