import { useEffect, useState } from 'react';
import * as schema from '@/bundles/schema/typescript/schema';
import * as locale from '@/common/utils/locale/locale';
import * as validator from '@/common/utils/validator';
import { DomainValidate } from '@/common/constants/domain';

export const useValidation = (formValue: schema.V1XStoragesOrderCreateRequest) => {
    const [validateMessage, setValidateMessage] = useState({
        service: 'error', // クラウド選択のエラーメッセージは表示しないが、「次へ」ボタンの判定条件のために使用する
        allowedDomain: '',
        body: '',
        cc: '',
        title: '',
    });

    useEffect(() => {
        // 両端の空白は許可する
        const ccFormat = formValue.cc.map((email) => email.trim());
        const msg = validator.Validate<validationOutputFormat>(
            {
                service: formValue.service,
                allowedDomain: formValue.allowedDomain,
                body: formValue.body,
                cc: ccFormat.join(','),
                title: formValue.title,
            },
            validation(),
            validationOutputFormat,
        );

        if (msg) {
            setValidateMessage({
                service: msg.service ? msg.service.toString() : '',
                allowedDomain: msg.allowedDomain ? msg.allowedDomain.toString() : '',
                body: msg.body ? msg.body.toString() : '',
                cc: msg.cc ? msg.cc.toString() : '',
                title: msg.title ? msg.title.toString() : '',
            });
        } else {
            setValidateMessage({
                service: '',
                allowedDomain: '',
                body: '',
                cc: '',
                title: '',
            });
        }
    }, [formValue.service, formValue.allowedDomain, formValue.body, formValue.cc, formValue.title]);

    return validateMessage;
};

const validation = (): validator.Constraints => {
    return {
        service: {
            exclusion: {
                within: [schema.V1ObjectsServiceEnum.Nonecloud],
                message: 'error',
            },
        },
        allowedDomain: {
            length: {
                maximum: DomainValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: DomainValidate.MAX_LENGTH }),
            },
            domain: {
                allowEmpty: true,
                message: locale.t(locale.keys.memberAuthManagement.orderCreate.validation.domain),
            },
        },
        body: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            length: {
                maximum: 2000,
                message: locale.t(locale.keys.validation.tooLong, { num: 2000 }),
            },
        },
        cc: {
            cc: {
                allowEmpty: true,
                message: '',
            },
        },
        title: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            length: {
                maximum: 100,
                message: locale.t(locale.keys.validation.tooLong, { num: 100 }),
            },
        },
    };
};

export type validationOutputFormat = {
    service: string;
    allowedDomain: string;
    body: string;
    cc: string;
    title: string;
};

const validationOutputFormat = (): validator.ValidationOutput => {
    return {
        service: null,
        allowedDomain: null,
        body: null,
        cc: null,
        title: null,
    };
};
