import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import logger from '@/common/utils/logger';
import { getSplitNewLineString, removeDoubleQuotation, csvPaser, splitString, sleep } from '@/common/utils/webappUtil';
import * as validator from '@/common/utils/validator';
import * as InvitationMember from '@/common/api/workspaceuser/invite/InvitationMember';
import * as schema from '@/bundles/schema/typescript/schema';
import { csvFormat } from '@/common/constants/csvFormat';
import { AdminAppContainer } from '@/admin/components/AdminAppContainer';
import { Variants } from '@/common/components/messages/CommonMessage';
import locale from '@/common/utils/locale';
import * as errorHandler from '@/common/utils/errorHandler';
import * as encoding from 'encoding-japanese';
import useUI, { State as UI } from '@/common/components/hooks/useUI';
import { isNullOrEmptyArray } from '@/common/utils/array-helper/arrayHelper';
import { createUserCollectiveExport } from '@/common/api/workspaceuser/collective/export';
import { exportCsv } from './exportCsv';
import { validateGroupName } from '@/common/models/groups/useGroup';
import { useMember } from '@/common/models/member/useMember';
import userRole from '@/common/constants/userRole';

const useCollectiveInviteContainer = () => {
    const appContainer = AdminAppContainer.useContainer();
    const [csvFileName, setCsvFileName] = useState<string>(locale.t(locale.keys.memberInvitation.collectiveInvite.noneFile));
    const [fileInput, setFileInput] = useState<Blob | null>(null);
    const [code, setCode] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [buttonDisable, setButtonDisable] = useState<boolean>(false);
    const [isSendEmailEnable, setIsSendEmailEnable] = useState<boolean>(true);
    const memberHooks = useMember();
    const ui = useUI();
    let blankLine: boolean = false;
    const userObjects: schema.V1ObjectsCollectiveInvite[] = [];

    useEffect(() => {
        ui.update(UI.Loaded);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setButtonDisable(true);
        if (!loading && fileInput) {
            setButtonDisable(false);
        }
    }, [loading, fileInput]);

    /**
     * ファイル選択時の処理
     * @param file csvfile or null
     */
    const onSelectedCSV = (file: File[] | null) => {
        if (file && file.length > 0) {
            // ファイル名を取得
            const fileName = file[0].name;
            // 事前に文字コードの確認のためにファイルを開く
            const reader = new FileReader();
            reader.readAsBinaryString(file[0]);
            reader.onload = () => {
                const str = reader.result as string;
                // BOM付きかチェック
                if (csvFormat.CSVFORMAT_BOM.test(str)) {
                    // BOM付きの場合UTF8
                    setCode('UTF8');
                } else {
                    // 文字コード判定
                    const detectResult = encoding.detect(str);
                    if (!detectResult) {
                        // 判定不能の文字コードはUTF8にしておく
                        logger.error(`onSelectedCSV() unknown encoding. fileName:${fileName}`);
                        setCode('UTF8');
                    } else {
                        const detectEncoding = detectResult as string;
                        setCode(detectEncoding);
                    }
                }
            };
            setCsvFileName(fileName);
            setFileInput(file[0]);
        } else {
            setCsvFileName('選択されていません');
            setFileInput(null);
        }
    };

    /**
     * csvのチェック
     * @param value １行ずつ
     * @param index 何行目
     */
    const csvImportCheck = (value: string[], index: number) => {
        // 一行ずつチェック
        // カラム数があっているか
        if (value.length >= csvFormat.COLUMNS) {
            if (blankLine) {
                // 空白行の次の行にデータがある場合
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.csvmincolumnerror, { row: index }),
                    variant: Variants.error,
                });
                return false;
            }
            // メールアドレスの""除去
            value[csvFormat.COL_IDX_EMAIL] = removeDoubleQuotation(value[csvFormat.COL_IDX_EMAIL]);
            // メールアドレス: 文字数の確認(54)、入力可能文字の確認
            const email = validator.Validate<{ email: string }>({ email: value[csvFormat.COL_IDX_EMAIL] }, InvitationMember.emailValidations(), InvitationMember.NewEmailValidation);
            if (email && email.email) {
                // エラー表示
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.csvimporterror, { row: index + 1, column: csvFormat.COL_IDX_EMAIL + 1, message: email.email.toString() }),
                    variant: Variants.error,
                });
                return false;
            }
            // 名前: 文字数(80)の確認、全角/半角の確認
            const name = validator.Validate<{ name: string }>({ name: value[csvFormat.COL_IDX_NAME] }, InvitationMember.nameValidations(), InvitationMember.newNameValidations);
            if (name && name.name) {
                // エラー表示
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.csvimporterror, { row: index + 1, column: csvFormat.COL_IDX_NAME + 1, message: name.name.toString() }),
                    variant: Variants.error,
                });
                return false;
            }
            // ヨミガナ: 文字数(80)の確認、全角/半角の確認
            const phoneticName = validator.Validate<{ phoneticName: string }>(
                { phoneticName: value[csvFormat.COL_IDX_PHONETICNAME] },
                InvitationMember.phoneticNameValidations(),
                InvitationMember.newPhoneticNameValidations,
            );
            if (phoneticName && phoneticName.phoneticName) {
                // エラー表示
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.csvimporterror, { row: index + 1, column: csvFormat.COL_IDX_PHONETICNAME + 1, message: phoneticName.phoneticName.toString() }),
                    variant: Variants.error,
                });
                return false;
            }
            // 言語: ja or en
            const language = validator.Validate<{ userLang: string }>({ userLang: value[csvFormat.COL_IDX_LANGUAGE] }, InvitationMember.userLangValidations(), InvitationMember.newUserLangValidations);
            if (language && language.userLang) {
                // エラー表示
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.csvimporterror, { row: index + 1, column: csvFormat.COL_IDX_LANGUAGE + 1, message: language.userLang.toString() }),
                    variant: Variants.error,
                });
                return false;
            }
            // 権限: admin or user or member
            // userとmemberは同じ権限。ユーザーのことをメンバーと呼称していた時の名残。
            const role = validator.Validate<{ userRole: string }>({ userRole: value[csvFormat.COL_IDX_ROLE] }, InvitationMember.userRoleValidations(), InvitationMember.newUserRoleValidations);
            if (role && role.userRole) {
                // エラー表示
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.csvimporterror, { row: index + 1, column: csvFormat.COL_IDX_ROLE + 1, message: role.userRole.toString() }),
                    variant: Variants.error,
                });
                return false;
            }
            // パスワード: 文字数(64)の確認、半角英数記号の確認
            // ただし、エクスポート時の「暗号化[***]」の場合はチェックしない
            if (value[csvFormat.COL_IDX_PASSWORD] !== schema.V1ObjectsPassExportConst.暗号化) {
                const password = validator.Validate<{ password: string }>(
                    { password: value[csvFormat.COL_IDX_PASSWORD] },
                    InvitationMember.passwordSettingValidations(),
                    InvitationMember.NewPasswordSettingValidation,
                );
                if (password && password.password) {
                    // エラー表示
                    appContainer.updateMessage({
                        isOpen: true,
                        message: locale.t(locale.keys.validation.csvimporterror, { row: index + 1, column: csvFormat.COL_IDX_PASSWORD + 1, message: password.password.toString() }),
                        variant: Variants.error,
                    });
                    return false;
                }
            }
            // 複合機のログインユーザー名
            if (value.length > csvFormat.COL_IDX_DEVICELOGINUSER && value[csvFormat.COL_IDX_DEVICELOGINUSER]) {
                const deviceLoginUser = validator.Validate<{ deviceLoginUser: string }>(
                    { deviceLoginUser: value[csvFormat.COL_IDX_DEVICELOGINUSER] },
                    InvitationMember.deviceLoginUserValidations(),
                    InvitationMember.NewDeviceLoginUserValidation,
                );
                if (deviceLoginUser && deviceLoginUser.deviceLoginUser) {
                    // エラー表示
                    appContainer.updateMessage({
                        isOpen: true,
                        message: locale.t(locale.keys.validation.csvimporterror, {
                            row: index + 1,
                            column: csvFormat.COL_IDX_DEVICELOGINUSER + 1,
                            message: deviceLoginUser.deviceLoginUser.toString(),
                        }),
                        variant: Variants.error,
                    });
                    return false;
                }
            }
            // PIN
            if (value.length > csvFormat.COL_IDX_PIN && value[csvFormat.COL_IDX_PIN]) {
                const pin = validator.Validate<{ pin: string }>({ pin: value[csvFormat.COL_IDX_PIN] }, InvitationMember.pinValidations(), InvitationMember.NewPinValidation);
                if (pin && pin.pin) {
                    appContainer.updateMessage({
                        isOpen: true,
                        message: locale.t(locale.keys.validation.csvimporterror, {
                            row: index + 1,
                            column: csvFormat.COL_IDX_PIN + 1,
                            message: pin.pin.toString(),
                        }),
                        variant: Variants.error,
                    });
                    return false;
                }
            }
            // 機械番号
            const mfpNumbers: string[] = [];
            if (value.length > csvFormat.COL_IDX_MFPNUMBER && value[csvFormat.COL_IDX_MFPNUMBER]) {
                // 配列に分割
                const splitRes = splitString(value[csvFormat.COL_IDX_MFPNUMBER], csvFormat.MFPNUMBER_SPLIT);
                for (let inputNumber of splitRes) {
                    const mfp = validator.Validate<{ mfpNumber: string }>({ mfpNumber: inputNumber }, InvitationMember.mfpNumberValidations(), InvitationMember.NewMfpNumberValidation);
                    if (mfp && mfp.mfpNumber) {
                        appContainer.updateMessage({
                            isOpen: true,
                            message: locale.t(locale.keys.validation.csvimporterror, {
                                row: index + 1,
                                column: csvFormat.COL_IDX_MFPNUMBER + 1,
                                message: mfp.mfpNumber.toString(),
                            }),
                            variant: Variants.error,
                        });
                        return false;
                    }
                    if (!mfpNumbers.includes(inputNumber.toUpperCase())) {
                        mfpNumbers.push(inputNumber.toUpperCase());
                    }
                }
            }
            // 連絡先Eメールアドレス
            if (value.length > csvFormat.COL_IDX_CONTACTEMAIL && value[csvFormat.COL_IDX_CONTACTEMAIL]) {
                const contactEmail = validator.Validate<{ contactEmail: string }>(
                    { contactEmail: value[csvFormat.COL_IDX_CONTACTEMAIL] },
                    InvitationMember.contactEmailValidations(),
                    InvitationMember.NewContactEmailValidation,
                );
                if (contactEmail && contactEmail.contactEmail) {
                    appContainer.updateMessage({
                        isOpen: true,
                        message: locale.t(locale.keys.validation.csvimporterror, {
                            row: index + 1,
                            column: csvFormat.COL_IDX_CONTACTEMAIL + 1,
                            message: contactEmail.contactEmail.toString(),
                        }),
                        variant: Variants.error,
                    });
                    return false;
                }
            }
            // グループ
            let groups = undefined;
            if (value.length > csvFormat.COL_IDX_GROUP && value[csvFormat.COL_IDX_GROUP]) {
                groups = [] as string[];
                for (let group of value[csvFormat.COL_IDX_GROUP].split(csvFormat.GROUP_SPLIT)) {
                    const groupValidation = validateGroupName(group, undefined, true);
                    if (groupValidation) {
                        appContainer.updateMessage({
                            isOpen: true,
                            message: locale.t(locale.keys.validation.csvimporterror, {
                                row: index + 1,
                                column: csvFormat.COL_IDX_GROUP + 1,
                                message: groupValidation,
                            }),
                            variant: Variants.error,
                        });
                        return false;
                    }

                    if (group.toLowerCase() === schema.V1ObjectsDefaultGroup.Everyone.toLowerCase()) {
                        groups.push(schema.V1ObjectsDefaultGroup.Everyone);
                    } else {
                        groups.push(group);
                    }
                }

                // 重複削除
                groups = groups.filter((x, i, self) => self.indexOf(x) === i);
            }

            // エラーがなければリクエストに追加
            // mfpNumberは1000件を超えると、超過分はAPIに無視される（サーバー負荷軽減のため）。
            userObjects.push({
                email: value[csvFormat.COL_IDX_EMAIL],
                name: value[csvFormat.COL_IDX_NAME],
                phoneticName: value[csvFormat.COL_IDX_PHONETICNAME],
                language: value[csvFormat.COL_IDX_LANGUAGE] as schema.Language,
                role: value[csvFormat.COL_IDX_ROLE],
                password: value[csvFormat.COL_IDX_PASSWORD],
                deviceLoginUser: value.length > csvFormat.COL_IDX_DEVICELOGINUSER && value[csvFormat.COL_IDX_DEVICELOGINUSER] ? value[csvFormat.COL_IDX_DEVICELOGINUSER] : '',
                pin: value.length > csvFormat.COL_IDX_PIN && value[csvFormat.COL_IDX_PIN] ? value[csvFormat.COL_IDX_PIN] : '',
                mfpNumber: mfpNumbers,
                contactEmail: value.length > csvFormat.COL_IDX_CONTACTEMAIL && value[csvFormat.COL_IDX_CONTACTEMAIL] ? value[csvFormat.COL_IDX_CONTACTEMAIL] : '',
                group: groups,
            });
            return true;
        }

        if (value.length === 1 && !value[0]) {
            blankLine = true;
            return true;
        }
        // カラム数エラー
        appContainer.updateMessage({
            isOpen: true,
            message: locale.t(locale.keys.validation.csvmincolumnerror, { row: index + 1 }),
            variant: Variants.error,
        });
        return false;
    };

    /**
     * ファイル読み込み失敗時にファイルをクリアする
     */
    const clearSelectedFile = () => {
        setLoading(false);
        setCsvFileName(locale.t(locale.keys.memberInvitation.collectiveInvite.noneFile));
        setFileInput(null);
    };

    /**
     * リクエストの作成とレスポンスの処理
     */
    const sendCollectiveInviteRequest = async () => {
        try {
            setLoading(true);
            ui.update(UI.Loading);
            const req: schema.V1WorkspaceuserInviteCollectiveCreateRequest = {
                workspace: appContainer.values.signinWorkspaceObject.id!,
                invitedUser: appContainer.values.signinWorkspaceUserObject.id,
                users: userObjects,
                fileName: csvFileName,
                sendEmail: isSendEmailEnable,
            };
            const response = await InvitationMember.collectiveRegistor(req, appContainer.values.authorizationCode);
            if (response) {
                switch (response.status) {
                    case schema.V1ObjectsModifyResponseStatusEnum.Accepted:
                        appContainer.updateMessage({
                            autoHideDuration: 3000,
                            isOpen: true,
                            message: locale.t(locale.keys.action.requestTimeout),
                            variant: Variants.success,
                        });
                        break;
                }
            }
            setLoading(false);
            ui.update(UI.Loaded);
        } catch (e) {
            // レスポンスエラーの時も選択ファイルクリア
            clearSelectedFile();
            errorHandler.handleApiError(appContainer, e);
            ui.update(UI.Loaded);
        }
    };

    /**
     * 「メンバーを登録」ボタンを押したときの処理
     */
    const onImportCsv = async () => {
        setLoading(true);
        // ファイルサイズ取得(0byteの場合エラー)
        if (fileInput && fileInput.size) {
            // ファイルの読み込み
            const reader = new FileReader();
            // 読み込み成功
            reader.onload = () => {
                const fileInput = reader.result as string;
                const lineInput = getSplitNewLineString(fileInput);
                blankLine = false;
                if (lineInput.length <= csvFormat.CSV_HEADER) {
                    // ヘッダー行のみのファイルはエラー
                    appContainer.updateMessage({
                        isOpen: true,
                        message: locale.t(locale.keys.validation.csvemptyerror),
                        variant: Variants.error,
                    });
                }
                for (let i = csvFormat.CSV_HEADER; i < lineInput.length; i += 1) {
                    // CSVパース
                    let spliteComma = [] as string[];
                    try {
                        spliteComma = csvPaser(lineInput[i], code);
                    } catch (e) {
                        // パースエラー
                        appContainer.updateMessage({
                            isOpen: true,
                            message: locale.t(locale.keys.validation.csvemptyerror, { row: i }),
                            variant: Variants.error,
                        });
                        ui.update(UI.Loaded);
                        break;
                    }
                    // 入力形式チェック
                    if (!csvImportCheck(spliteComma, i)) {
                        ui.update(UI.Loaded);
                        break;
                    }
                    // 1000行読み込んだ場合
                    if (i >= csvFormat.CSV_MAX_LINE + csvFormat.CSV_HEADER - 1) {
                        if (!isNullOrEmptyArray(userObjects)) {
                            // apiにリクエスト
                            sendCollectiveInviteRequest();
                            break;
                        }
                    }
                    // 最終行まで読み込んだ場合
                    if (i === lineInput.length - 1) {
                        if (blankLine) {
                            if (!isNullOrEmptyArray(userObjects)) {
                                // apiにリクエスト
                                sendCollectiveInviteRequest();
                            } else {
                                // データ行なし
                                appContainer.updateMessage({
                                    isOpen: true,
                                    message: locale.t(locale.keys.validation.csvemptyerror),
                                    variant: Variants.error,
                                });
                            }
                        } else {
                            // qsのcsvインポート仕様に合わせる
                            // 最終行はEOFの前に改行がなければエラー
                            appContainer.updateMessage({
                                isOpen: true,
                                message: locale.t(locale.keys.validation.csvimporteoferror, { row: lineInput.length }),
                                variant: Variants.error,
                            });
                            ui.update(UI.Loaded);
                        }
                    }
                }
                clearSelectedFile();
            };
            // 読み込み失敗
            reader.onerror = () => {
                clearSelectedFile();
                ui.update(UI.Loaded);
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.csvemptyerror),
                    variant: Variants.error,
                });
                logger.error('onSelectedCSV ファイルが読み込めませんでした。', csvFileName);
            };

            // 読み込み開始
            if (code === 'SJIS' || code === 'UTF8') {
                reader.readAsText(fileInput, code);
            } else {
                clearSelectedFile();
                // それ以外対応していない文字コードとして扱う
                appContainer.updateMessage({
                    isOpen: true,
                    message: locale.t(locale.keys.validation.invalidCharacterCode),
                    variant: Variants.error,
                });
            }
        } else {
            clearSelectedFile();
            appContainer.updateMessage({
                isOpen: true,
                message: locale.t(locale.keys.validation.csvemptyerror),
                variant: Variants.error,
            });
        }
    };

    const onChangeSendEmail = (enabled: boolean) => {
        setIsSendEmailEnable(enabled);
    };

    const onClickExportUser = async () => {
        try {
            ui.update(UI.Loading);
            const wsMember = await memberHooks.indexWorkspaceuser(appContainer.values.signinWorkspaceObject.id!, appContainer.values.authorizationCode);
            // 管理者とユーザー権限かつサインアップ済みのユーザーをエクスポート
            const maxCount = wsMember.users.filter((member) => (member.role === userRole.admin || member.role === userRole.member) && member.invitationVerified).length;
            const limit = 50;
            let exportData: schema.V1ObjectsCollectiveInvite[] = [];
            for (let offset = 0; offset < maxCount + 1; offset += limit) {
                const result = await createUserCollectiveExport(
                    {
                        workspace: appContainer.values.signinWorkspaceObject.id!,
                        limit,
                        offset,
                    },
                    appContainer.values.authorizationCode,
                );
                if (result.users) {
                    exportData = [...exportData, ...result.users];
                }
                await sleep(1000);
            }
            exportCsv(exportData, appContainer.values.signinWorkspaceObject.language!);
            ui.update(UI.Loaded);
        } catch (e) {
            ui.update(UI.Loaded);
            errorHandler.handleApiError(appContainer, e);
            clearSelectedFile();
        }
    };

    return {
        csvFileName,
        fileInput,
        buttonDisable,
        ui,
        onSelectedCSV,
        onImportCsv,
        isSendEmailEnable,
        onChangeSendEmail,
        onClickExportUser,
    };
};

export const CollectiveInviteContainer = createContainer(useCollectiveInviteContainer);
